import { string } from 'yup';

const passwordValidations = {
  hasDigit: /\d/,
  hasLowerCase: /[a-zäöüß]/,
  hasMinEightCharacters: /^.{8,}$/,
  hasNoWhiteSpace: /^\S+$/,
  hasSymbol: /[!"#$%&\\'()*+,-.\\/:;<=>?@[\]^_`{|}~]/,
  hasUpperCase: /[A-ZÄÖÜ]/,
} as const;

const passwordValidationSchema = Object.entries(passwordValidations).reduce(
  (schema, [
    key,
    validation,
  ]) => schema.test({
    exclusive: false,
    message: key,
    test: (value) => {
      if (!value) {
        return false;
      }

      return validation.test(value);
    },
  }),
  string().required(),
);

export {
  passwordValidations,
  passwordValidationSchema,
};
