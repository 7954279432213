import { createOperationsGenerator } from '#image';
import { joinURL } from 'ufo';
import type { ProviderGetImage } from '@nuxt/image';

const operationsGenerator = createOperationsGenerator({
  joinWith: ',',
  keyMap: {
    format: 'f',
    height: 'h',
    quality: 'q',
    width: 'w',
  },
});

const version = 'v1';

export const getImage: ProviderGetImage = (
  src,
  {
    baseURL,
    modifiers = {},
  } = {},
) => {
  if (!baseURL) {
    // eslint-disable-next-line no-param-reassign
    baseURL = useRuntimeConfig().public.siteUrl;
  }

  const host = `${baseURL}/${version}/`;

  const originalURL = new URL(src);
  const operations = operationsGenerator(modifiers);
  const url = joinURL(host, `${originalURL.pathname}${originalURL.search || '?'}${operations
    ? `&${operations}`
    : ''}`);
  return { url };
};
