import {
  tv,
  type VariantProps,
} from 'tailwind-variants';

const badge = tv({
  base: 'flex items-center justify-center rounded-full border font-semibold uppercase tracking-wide',
  compoundVariants: [
    {
      class: 'bg-dark text-white',
      color: 'dark',
      variant: 'solid',
    },
    {
      class: 'bg-orange text-white',
      color: 'orange',
      variant: 'solid',
    },
    {
      class: 'bg-purple text-white',
      color: 'purple',
      variant: 'solid',
    },
    {
      class: 'bg-white text-dark',
      color: 'white',
      variant: 'solid',
    },
    {
      class: 'bg-transparent text-dark',
      color: 'dark',
      variant: 'outline',
    },
    {
      class: 'bg-transparent text-orange',
      color: 'orange',
      variant: 'outline',
    },
    {
      class: 'bg-transparent text-white',
      color: 'purple',
      variant: 'outline',
    },
    {
      class: 'bg-transparent text-white',
      color: 'white',
      variant: 'outline',
    },
  ],
  variants: {
    color: {
      dark: 'border-dark',
      orange: 'border-orange',
      purple: 'border-purple',
      white: 'border-white',
    },
    size: {
      lg: 'px-8 py-3 text-base',
      md: 'px-4 py-2 text-sm',
      sm: 'px-2 py-1 text-xs',
      xs: 'px-1.5 py-0 text-xs',
    },
    variant: {
      outline: '',
      solid: '',
    },
  },
});

type BadgeVariants = VariantProps<typeof badge>;

export {
  badge,
  type BadgeVariants,
};
