/* eslint-disable no-console */
import { isProduction } from './environments';

type LogFunction = (...args: any[]) => void;

interface GenericLogger {
  error: LogFunction;
  info: LogFunction;
  warn: LogFunction;
}

interface LoggerOptions {
  forceConsoleError?: boolean;
}

class Logger {
  static _isLoggerAvailable = false;

  static _logger: GenericLogger;

  static error(message: string, details?: any, options?: LoggerOptions) {
    const forceConsoleError = options?.forceConsoleError ?? false;

    if (forceConsoleError) {
      console.error(message, details);
    }

    if (!isProduction || !Logger._isLoggerAvailable) {
      console.error(message, details);
    } else if (!forceConsoleError) {
      Logger._logger?.error(message, details);
    }
  }

  static info(message: string, details?: object) {
    if (!isProduction || !Logger._isLoggerAvailable) {
      console.info(message, details);
    } else {
      Logger._logger?.info(message, details);
    }
  }

  static setLogger(logger: GenericLogger) {
    Logger._logger = logger;
    Logger._isLoggerAvailable = true;
  }

  static warn(message: string, details?: object) {
    if (!isProduction || !Logger._isLoggerAvailable) {
      console.warn(message, details);
    } else {
      Logger._logger?.warn(message, details);
    }
  }
}

export { Logger };
