<script setup lang="ts">
import { Logger } from 'utils';
import type { NuxtError } from '#app';

const FULLSTORY_TAG_ERRORPAGE = 'error-page';
const FULLSTORY_TAG_WARNINGPAGE = 'warning-page';
const ERRORCODE_404 = 404;

withDefaults(defineProps<{
  error: NuxtError;
}>(), {  })

const { BASE_URL } = useBaseUrl();

const fullstoryTag = ref(FULLSTORY_TAG_WARNINGPAGE);

if (__props.error.statusCode !== ERRORCODE_404) {
  fullstoryTag.value = FULLSTORY_TAG_ERRORPAGE;

  Logger.error('error-page', {
    data: __props.error,
    stack: new Error('error page shown').stack,
  });
}
</script>

<template>
  <main
    aria-labelledby="primary-heading"
    class="w-full"
    :data-fs="fullstoryTag ? fullstoryTag : null"
  >
    <div class="flex min-h-screen flex-col items-center justify-center bg-purple-light p-8">
      <div class="mx-auto w-full max-w-3xl space-y-4 text-center">
        <h1 class="my-0 whitespace-pre-line text-balance text-4xl font-black !leading-tight md:text-6xl">
          {{ $t('error.headline') }}
        </h1>
        <p class="mt-2.5 text-xl">
          {{ $t('error.subline') }}
        </p>
        <Button
          as-child
          class="min-w-[310px] max-w-[340px] md:min-w-[340px] md:max-w-[440px]"
          variant="info"
        >
          <a :href="BASE_URL">
            {{ $t('error.backToHomepage') }}
          </a>
        </Button>
      </div>
    </div>
  </main>
</template>
