import { createConsola } from 'consola';
import { isProduction } from './environments';

enum LOG_LEVELS {
  DEBUG = 4,
  ERROR = 0,
  INFO = 3,
  LOG = 2,
  SILENT = Number.NEGATIVE_INFINITY,
  TRACE = 5,
  VERBOSE = Number.POSITIVE_INFINITY,
  WARN = 1,
};

const DEFAULT_LOG_LEVEL = isProduction
  ? LOG_LEVELS.INFO
  : LOG_LEVELS.DEBUG;

interface ConsolaLoggerOptions {
  locale?: string;
  /**
   * Should be a valid log level defined in the consola package
   * @default DEFAULT_LOG_LEVEL
   */
  logLevel?: LOG_LEVELS;
  service: string;
  uniqueIdentifier?: string;
  version: string;
}

const createConsolaLogger = ({
  logLevel,
  service,
  uniqueIdentifier,
  version,
}: ConsolaLoggerOptions) => createConsola({
  level: logLevel ?? DEFAULT_LOG_LEVEL,
  reporters: [
    {
      log: (logObj) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify({
          level: logObj.type,
          message: logObj.args[0],
          payload: logObj.args[1],
          service,
          uniqueIdentifier,
          version,
        }));
      },
    },
  ],
});

export { createConsolaLogger };
