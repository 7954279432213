import { default as _91_46_46_46slug_93O5ofFNL4m4Meta } from "/app/packages/shop/pages/[...slug].vue?macro=true";
import { default as _91product_93wOJlIYPzTkMeta } from "/app/packages/shop/pages/product/[productType]/[product].vue?macro=true";
import { default as searchErnJx0MT89Meta } from "/app/packages/shop/pages/search.vue?macro=true";
import { default as _91shopSlug_93wV9lVQaNelMeta } from "/app/packages/shop/pages/shop/[shopSlug].vue?macro=true";
import { default as testpage_45product_45quality_45containerben64G9afLMeta } from "/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue?macro=true";
import { default as wishlistJul8bWHV2vMeta } from "/app/packages/shop/pages/wishlist.vue?macro=true";
import { default as component_45stubthJZ4SxJr5Meta } from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubthJZ4SxJr5 } from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de-at",
    path: "/de-at/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de-ch",
    path: "/de-ch/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de-it",
    path: "/de-it/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de-lu",
    path: "/de-lu/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de-li",
    path: "/de-li/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl-be",
    path: "/nl-be/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr-be",
    path: "/fr-be/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr-ch",
    path: "/fr-ch/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr-lu",
    path: "/fr-lu/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___uk",
    path: "/uk/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___it-ch",
    path: "/it-ch/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    component: () => import("/app/packages/shop/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___de",
    path: "/de/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___de-at",
    path: "/de-at/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___de-ch",
    path: "/de-ch/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___de-it",
    path: "/de-it/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___de-lu",
    path: "/de-lu/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___de-li",
    path: "/de-li/produkt/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___nl",
    path: "/nl/product/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___nl-be",
    path: "/nl-be/product/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___fr",
    path: "/fr/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___fr-be",
    path: "/fr-be/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___fr-ch",
    path: "/fr-ch/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___fr-lu",
    path: "/fr-lu/produit/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___uk",
    path: "/uk/product/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___it",
    path: "/it/prodotto/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___it-ch",
    path: "/it-ch/prodotto/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-productType-product___es",
    path: "/es/producto/:productType()/:product()",
    component: () => import("/app/packages/shop/pages/product/[productType]/[product].vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-at",
    path: "/de-at/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-ch",
    path: "/de-ch/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-it",
    path: "/de-it/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-lu",
    path: "/de-lu/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-li",
    path: "/de-li/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___nl",
    path: "/nl/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___nl-be",
    path: "/nl-be/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr-be",
    path: "/fr-be/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr-ch",
    path: "/fr-ch/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr-lu",
    path: "/fr-lu/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___uk",
    path: "/uk/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___it",
    path: "/it/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___it-ch",
    path: "/it-ch/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/app/packages/shop/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___de",
    path: "/de/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___de-at",
    path: "/de-at/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___de-ch",
    path: "/de-ch/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___de-it",
    path: "/de-it/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___de-lu",
    path: "/de-lu/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___de-li",
    path: "/de-li/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___nl",
    path: "/nl/winkel/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___nl-be",
    path: "/nl-be/winkel/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___fr",
    path: "/fr/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___fr-be",
    path: "/fr-be/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___fr-ch",
    path: "/fr-ch/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___fr-lu",
    path: "/fr-lu/boutique/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___uk",
    path: "/uk/shop/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___it",
    path: "/it/negozio/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___it-ch",
    path: "/it-ch/negozio/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "shop-shopSlug___es",
    path: "/es/tienda/:shopSlug()",
    component: () => import("/app/packages/shop/pages/shop/[shopSlug].vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___de",
    path: "/de/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___de-at",
    path: "/de-at/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___de-ch",
    path: "/de-ch/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___de-it",
    path: "/de-it/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___de-lu",
    path: "/de-lu/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___de-li",
    path: "/de-li/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___nl",
    path: "/nl/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___nl-be",
    path: "/nl-be/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___fr",
    path: "/fr/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___fr-be",
    path: "/fr-be/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___fr-ch",
    path: "/fr-ch/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___fr-lu",
    path: "/fr-lu/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___uk",
    path: "/uk/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___it",
    path: "/it/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___it-ch",
    path: "/it-ch/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-testpage-product-quality-container___es",
    path: "/es/storyblok/testpage-product-quality-container",
    component: () => import("/app/packages/shop/pages/storyblok/testpage-product-quality-container.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de",
    path: "/de/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-at",
    path: "/de-at/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-ch",
    path: "/de-ch/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-it",
    path: "/de-it/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-lu",
    path: "/de-lu/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-li",
    path: "/de-li/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___nl",
    path: "/nl/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___nl-be",
    path: "/nl-be/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___fr",
    path: "/fr/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___fr-be",
    path: "/fr-be/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___fr-ch",
    path: "/fr-ch/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___fr-lu",
    path: "/fr-lu/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___uk",
    path: "/uk/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___it",
    path: "/it/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___it-ch",
    path: "/it-ch/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___es",
    path: "/es/wishlist",
    component: () => import("/app/packages/shop/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/de-AT-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/de-CH-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/de-IT-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/de-LU-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/de-LI-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/nl-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/nl-BE-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/fr-BE-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/fr-CH-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/fr-LU-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/uk-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/it-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/it-CH-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  },
  {
    name: component_45stubthJZ4SxJr5Meta?.name,
    path: "/es-sitemap.xml",
    component: component_45stubthJZ4SxJr5
  }
]