/* eslint-disable perfectionist/sort-objects */
const colors = {
  brown: '#854D0E',
  dark: '#1E1E1E',
  semidark: '#454545',
  'grey-dark': '#424E5F',
  'grey-semidark': '#48566A',
  grey: '#C7CEDF',
  'grey-mid': '#E3E6EB',
  'grey-light': '#F4F4F7',
  'grey-extra-light': '#F8F9FB',
  white: '#FFF',
  'green-dark': '#16B364',
  green: '#28B970',
  'green-light': '#9FE1AE',
  'orange-dark': '#EF9402',
  orange: '#F4A018',
  red: '#F61D37',
  'purple-dark': '#6F4DD9',
  purple: '#7E5AF0',
  'purple-light': '#E8E2FC',
  'purple-extra-light': '#F6F5FD',
  'purple-hazy': '#B5B5FF',
  highlight: '#F4FF5E',
  yellow: '#F9C515',
  pink: '#FB42B6',
  transparent: 'transparent',
} as const;

export { colors };
