export default defineNuxtPlugin(() => {
  const {
    datadogClientToken: clientToken,
    version,
  } = useRuntimeConfig().public;

  if (!clientToken) {
    return;
  }

  const { initializeLogger } = useDatadogLogs();

  initializeLogger({
    clientToken,
    serviceName: 'shop-frontend',
    version,
  });
});
