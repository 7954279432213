import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["AllProductQualityBlocks","CalculatedPrice","CommunicationBar","DefaultLayout","DesignById","DesignFilters","DesignSearch","Designs","LinkinghubItems","NavigationItem","PricesByProductKey","ProductData","ProductDetailPage","ProductQualityBlock","ProductTypes","PromoLinkByCode","Redirect","ShopBestsellingDesigns","Shop","SimilarDesigns","Sitemap","StoryblokStory"]}
export const GqlAllProductQualityBlocks = (...params) => useGql()('AllProductQualityBlocks', ...params)
export const GqlCalculatedPrice = (...params) => useGql()('CalculatedPrice', ...params)
export const GqlCommunicationBar = (...params) => useGql()('CommunicationBar', ...params)
export const GqlDefaultLayout = (...params) => useGql()('DefaultLayout', ...params)
export const GqlDesignById = (...params) => useGql()('DesignById', ...params)
export const GqlDesignFilters = (...params) => useGql()('DesignFilters', ...params)
export const GqlDesignSearch = (...params) => useGql()('DesignSearch', ...params)
export const GqlDesigns = (...params) => useGql()('Designs', ...params)
export const GqlLinkinghubItems = (...params) => useGql()('LinkinghubItems', ...params)
export const GqlNavigationItem = (...params) => useGql()('NavigationItem', ...params)
export const GqlPricesByProductKey = (...params) => useGql()('PricesByProductKey', ...params)
export const GqlProductData = (...params) => useGql()('ProductData', ...params)
export const GqlProductDetailPage = (...params) => useGql()('ProductDetailPage', ...params)
export const GqlProductQualityBlock = (...params) => useGql()('ProductQualityBlock', ...params)
export const GqlProductTypes = (...params) => useGql()('ProductTypes', ...params)
export const GqlPromoLinkByCode = (...params) => useGql()('PromoLinkByCode', ...params)
export const GqlRedirect = (...params) => useGql()('Redirect', ...params)
export const GqlShop = (...params) => useGql()('Shop', ...params)
export const GqlShopBestsellingDesigns = (...params) => useGql()('ShopBestsellingDesigns', ...params)
export const GqlSimilarDesigns = (...params) => useGql()('SimilarDesigns', ...params)
export const GqlSitemap = (...params) => useGql()('Sitemap', ...params)
export const GqlStoryblokStory = (...params) => useGql()('StoryblokStory', ...params)