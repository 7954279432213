<script setup lang="ts">
import {
  Primitive,
  type PrimitiveProps,
} from 'radix-vue';
import {
  buttonVariants,
  type ButtonVariants,
} from './variants';
import type { HTMLAttributes } from 'vue';

interface Props extends PrimitiveProps {
  class?: HTMLAttributes['class'];
  isLoading?: boolean;
  isShopButton?: boolean;
  size?: ButtonVariants['size'];
  type?: 'submit' | 'button';
  variant?: ButtonVariants['variant'];
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
  class: '',
  isLoading: false,
  isShopButton: false,
  size: 'medium',
  type: 'button',
  variant: 'secondary',
});
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size, isShopButton }), props.class)"
    :type="type"
  >
    <UiLoadingSpinner
      v-if="isLoading"
      is-small
    />
    <slot
      v-else
      name="icon"
    />
    <slot></slot>
  </Primitive>
</template>
./variants
