const useBaseUrl = () => {
  const BASE_URL = useRuntimeConfig()?.public?.baseUrl as string
    || 'https://www.juniqe.com';

  const createUrlWithSearchParams = (
    url: string,
    params?: Record<string, string>,
    keepOrigin?: boolean,
  ) => {
    if (!params || Object.keys(params).length === 0) {
      return keepOrigin
        ? url
        : `${new URL(url, BASE_URL).pathname}`;
    }

    const nonEmptySearchParams = Object.entries(params)
      .filter(([
        _,
        value,
      ]) => !!value);

    if (!nonEmptySearchParams.length) {
      return url;
    }

    const oldUrl = new URL(url, BASE_URL);

    const arr = new Map([
      ...oldUrl.searchParams.entries(),
      ...nonEmptySearchParams,
    ]);

    const newSearchParams = new URLSearchParams([ ...arr ]);
    const pathWithParams = `${oldUrl.pathname}?${newSearchParams}`;

    return keepOrigin
      ? `${oldUrl.origin}${pathWithParams}`
      : pathWithParams;
  };

  return {
    BASE_URL,
    createUrlWithSearchParams,
  };
};

export { useBaseUrl };
