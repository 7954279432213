export default defineNuxtPlugin(() => {
  const {
    gqlHost,
    public: { gqlClientHost },
  } = useRuntimeConfig();

  const host = gqlHost || gqlClientHost;
  if (host) {
    useGqlHost(host);
  }
});
