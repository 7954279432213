import { type Router } from 'vue-router';

export default defineNuxtPlugin((nuxtApp) => {
  const referer = useState('referer', () => '');

  if (process.server) {
    const ssrReferer = useRequestHeader('referer') || 'no ssr referer';
    referer.value = ssrReferer;
  }

  nuxtApp.hook('page:finish', () => {
    const router = nuxtApp.$router as Router;
    const from = router.currentRoute.value.fullPath || '';
    if (from) {
      referer.value = from;
    }
  });
});
