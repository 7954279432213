import { configure } from 'vee-validate';

/**
 * This helper functions configures vee-validate to have the expected behavior for forms.
 */
const configureForms = () => {
  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: false,
    validateOnModelUpdate: false,
  });
};

export { configureForms };
