
// @ts-nocheck


export const localeCodes =  [
  "de",
  "de-at",
  "de-ch",
  "de-it",
  "de-lu",
  "de-li",
  "nl",
  "nl-be",
  "fr",
  "fr-be",
  "fr-ch",
  "fr-lu",
  "uk",
  "it",
  "it-ch",
  "es"
]

export const localeLoaders = {
  "de": [{ key: "../translations/de-DE.ts", load: () => import("../translations/de-DE.ts?hash=545aecaf&locale=de" /* webpackChunkName: "locale__app_packages_shop_translations_de_DE_ts" */), cache: false }],
  "de-at": [{ key: "../translations/de-AT.ts", load: () => import("../translations/de-AT.ts?hash=73a4381f&locale=de-at" /* webpackChunkName: "locale__app_packages_shop_translations_de_AT_ts" */), cache: false }],
  "de-ch": [{ key: "../translations/de-CH.ts", load: () => import("../translations/de-CH.ts?hash=ebc1eaff&locale=de-ch" /* webpackChunkName: "locale__app_packages_shop_translations_de_CH_ts" */), cache: false }],
  "de-it": [{ key: "../translations/de-IT.ts", load: () => import("../translations/de-IT.ts?hash=a149403a&locale=de-it" /* webpackChunkName: "locale__app_packages_shop_translations_de_IT_ts" */), cache: false }],
  "de-lu": [{ key: "../translations/de-LU.ts", load: () => import("../translations/de-LU.ts?hash=f2f0e4d9&locale=de-lu" /* webpackChunkName: "locale__app_packages_shop_translations_de_LU_ts" */), cache: false }],
  "de-li": [{ key: "../translations/de-LI.ts", load: () => import("../translations/de-LI.ts?hash=469ad386&locale=de-li" /* webpackChunkName: "locale__app_packages_shop_translations_de_LI_ts" */), cache: false }],
  "nl": [{ key: "../translations/nl-NL.ts", load: () => import("../translations/nl-NL.ts?hash=96c5263d&locale=nl" /* webpackChunkName: "locale__app_packages_shop_translations_nl_NL_ts" */), cache: false }],
  "nl-be": [{ key: "../translations/nl-BE.ts", load: () => import("../translations/nl-BE.ts?hash=c0a18660&locale=nl-be" /* webpackChunkName: "locale__app_packages_shop_translations_nl_BE_ts" */), cache: false }],
  "fr": [{ key: "../translations/fr-FR.ts", load: () => import("../translations/fr-FR.ts?hash=a7dceb60&locale=fr" /* webpackChunkName: "locale__app_packages_shop_translations_fr_FR_ts" */), cache: false }],
  "fr-be": [{ key: "../translations/fr-BE.ts", load: () => import("../translations/fr-BE.ts?hash=0bf4ed13&locale=fr-be" /* webpackChunkName: "locale__app_packages_shop_translations_fr_BE_ts" */), cache: false }],
  "fr-ch": [{ key: "../translations/fr-CH.ts", load: () => import("../translations/fr-CH.ts?hash=2dfd8a6e&locale=fr-ch" /* webpackChunkName: "locale__app_packages_shop_translations_fr_CH_ts" */), cache: false }],
  "fr-lu": [{ key: "../translations/fr-LU.ts", load: () => import("../translations/fr-LU.ts?hash=a716dc76&locale=fr-lu" /* webpackChunkName: "locale__app_packages_shop_translations_fr_LU_ts" */), cache: false }],
  "uk": [{ key: "../translations/en-GB.ts", load: () => import("../translations/en-GB.ts?hash=a834ca93&locale=uk" /* webpackChunkName: "locale__app_packages_shop_translations_en_GB_ts" */), cache: false }],
  "it": [{ key: "../translations/it-IT.ts", load: () => import("../translations/it-IT.ts?hash=79210767&locale=it" /* webpackChunkName: "locale__app_packages_shop_translations_it_IT_ts" */), cache: false }],
  "it-ch": [{ key: "../translations/it-CH.ts", load: () => import("../translations/it-CH.ts?hash=32a9d87f&locale=it-ch" /* webpackChunkName: "locale__app_packages_shop_translations_it_CH_ts" */), cache: false }],
  "es": [{ key: "../translations/es-ES.ts", load: () => import("../translations/es-ES.ts?hash=e64791ef&locale=es" /* webpackChunkName: "locale__app_packages_shop_translations_es_ES_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "language": "de-DE",
      "files": [
        "/app/packages/shop/translations/de-DE.ts"
      ]
    },
    {
      "code": "de-at",
      "language": "de-AT",
      "files": [
        "/app/packages/shop/translations/de-AT.ts"
      ]
    },
    {
      "code": "de-ch",
      "language": "de-CH",
      "files": [
        "/app/packages/shop/translations/de-CH.ts"
      ]
    },
    {
      "code": "de-it",
      "language": "de-IT",
      "files": [
        "/app/packages/shop/translations/de-IT.ts"
      ]
    },
    {
      "code": "de-lu",
      "language": "de-LU",
      "files": [
        "/app/packages/shop/translations/de-LU.ts"
      ]
    },
    {
      "code": "de-li",
      "language": "de-LI",
      "files": [
        "/app/packages/shop/translations/de-LI.ts"
      ]
    },
    {
      "code": "nl",
      "language": "nl-NL",
      "files": [
        "/app/packages/shop/translations/nl-NL.ts"
      ]
    },
    {
      "code": "nl-be",
      "language": "nl-BE",
      "files": [
        "/app/packages/shop/translations/nl-BE.ts"
      ]
    },
    {
      "code": "fr",
      "language": "fr-FR",
      "files": [
        "/app/packages/shop/translations/fr-FR.ts"
      ]
    },
    {
      "code": "fr-be",
      "language": "fr-BE",
      "files": [
        "/app/packages/shop/translations/fr-BE.ts"
      ]
    },
    {
      "code": "fr-ch",
      "language": "fr-CH",
      "files": [
        "/app/packages/shop/translations/fr-CH.ts"
      ]
    },
    {
      "code": "fr-lu",
      "language": "fr-LU",
      "files": [
        "/app/packages/shop/translations/fr-LU.ts"
      ]
    },
    {
      "code": "uk",
      "language": "en-GB",
      "files": [
        "/app/packages/shop/translations/en-GB.ts"
      ]
    },
    {
      "code": "it",
      "language": "it-IT",
      "files": [
        "/app/packages/shop/translations/it-IT.ts"
      ]
    },
    {
      "code": "it-ch",
      "language": "it-CH",
      "files": [
        "/app/packages/shop/translations/it-CH.ts"
      ]
    },
    {
      "code": "es",
      "language": "es-ES",
      "files": [
        "/app/packages/shop/translations/es-ES.ts"
      ]
    }
  ],
  "defaultLocale": "uk",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "translations",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "juniqe-locale",
    "cookieSecure": false,
    "fallbackLocale": "de",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.juniqe.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "language": "de-DE",
    "files": [
      {
        "path": "/app/packages/shop/translations/de-DE.ts"
      }
    ]
  },
  {
    "code": "de-at",
    "language": "de-AT",
    "files": [
      {
        "path": "/app/packages/shop/translations/de-AT.ts"
      }
    ]
  },
  {
    "code": "de-ch",
    "language": "de-CH",
    "files": [
      {
        "path": "/app/packages/shop/translations/de-CH.ts"
      }
    ]
  },
  {
    "code": "de-it",
    "language": "de-IT",
    "files": [
      {
        "path": "/app/packages/shop/translations/de-IT.ts"
      }
    ]
  },
  {
    "code": "de-lu",
    "language": "de-LU",
    "files": [
      {
        "path": "/app/packages/shop/translations/de-LU.ts"
      }
    ]
  },
  {
    "code": "de-li",
    "language": "de-LI",
    "files": [
      {
        "path": "/app/packages/shop/translations/de-LI.ts"
      }
    ]
  },
  {
    "code": "nl",
    "language": "nl-NL",
    "files": [
      {
        "path": "/app/packages/shop/translations/nl-NL.ts"
      }
    ]
  },
  {
    "code": "nl-be",
    "language": "nl-BE",
    "files": [
      {
        "path": "/app/packages/shop/translations/nl-BE.ts"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-FR",
    "files": [
      {
        "path": "/app/packages/shop/translations/fr-FR.ts"
      }
    ]
  },
  {
    "code": "fr-be",
    "language": "fr-BE",
    "files": [
      {
        "path": "/app/packages/shop/translations/fr-BE.ts"
      }
    ]
  },
  {
    "code": "fr-ch",
    "language": "fr-CH",
    "files": [
      {
        "path": "/app/packages/shop/translations/fr-CH.ts"
      }
    ]
  },
  {
    "code": "fr-lu",
    "language": "fr-LU",
    "files": [
      {
        "path": "/app/packages/shop/translations/fr-LU.ts"
      }
    ]
  },
  {
    "code": "uk",
    "language": "en-GB",
    "files": [
      {
        "path": "/app/packages/shop/translations/en-GB.ts"
      }
    ]
  },
  {
    "code": "it",
    "language": "it-IT",
    "files": [
      {
        "path": "/app/packages/shop/translations/it-IT.ts"
      }
    ]
  },
  {
    "code": "it-ch",
    "language": "it-CH",
    "files": [
      {
        "path": "/app/packages/shop/translations/it-CH.ts"
      }
    ]
  },
  {
    "code": "es",
    "language": "es-ES",
    "files": [
      {
        "path": "/app/packages/shop/translations/es-ES.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
